import React from 'react';
import {
  Card, Col, Container, Row, Spinner,
} from 'react-bootstrap';

import { __ } from '../../../helpers/translations';
import Section from '../../common/Section';

function Loading() {
  return (
    <Section className="py-0">
      <Row className="flex-center min-vh-100 py-6">
        <Col sm={10} md={8} lg={6} xl={5} xxl={4}>
          <Container className="text-center">
            <Card>
              <Card.Body className="p-4 p-sm-5">
                <div className="display-1 text-300 fs-error">
                  <Spinner animation="border" role="status" />
                </div>
                <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                  {__('G52')}
                </p>
              </Card.Body>
            </Card>
          </Container>
        </Col>
      </Row>
    </Section>
  );
}
export default Loading;
