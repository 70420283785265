/* eslint-disable no-underscore-dangle */
import { sprintf } from 'sprintf-js';

import data from '../frontend.json';
import { getCookieValue } from './utils';

const translations = {};
data.forEach(({
  code, en, fr, pt, es, de, it,
}) => {
  translations[code] = {
    en, fr, pt, es, de, it,
  };
});

export function __(code, language = null, params = []) {
  if (!translations[code]) {
    return null;
  }

  const entry = translations[code];
  const languages = Object.keys(entry);
  let currentLanguage = null;

  if (language && languages.includes(language)) {
    currentLanguage = language;
  }

  if (!currentLanguage) {
    const cookieLanguage = getCookieValue('language');
    if (languages.includes(cookieLanguage)) {
      currentLanguage = cookieLanguage;
    }
  }

  if (!currentLanguage) {
    const userLanguage = (navigator.language.split('-') || [])[0];
    if (languages.includes(userLanguage)) {
      currentLanguage = userLanguage;
    }
  }

  if (!currentLanguage) {
    currentLanguage = 'en';
  }

  return sprintf(entry[currentLanguage], ...params);
}
