import { __ } from '../../../../helpers/translations';

export const defaultPeers = [
  {
    _id: 'preview_self',
    firstName: __('G259'),
    lastName: '',
    respondentId: 'self',
  },
  {
    _id: 'preview_peerb',
    firstName: __('G260'),
    lastName: '',
    respondentId: 'b',
  },
  {
    _id: 'preview_peerc',
    firstName: __('G261'),
    lastName: '',
    respondentId: 'c',
  },
  {
    _id: 'preview_peerd',
    firstName: __('G262'),
    lastName: '',
    respondentId: 'd',
  },
];
