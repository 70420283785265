/* eslint-disable react/no-array-index-key */
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert,
  Button, Form, Modal,
} from 'react-bootstrap';
import validator from 'validator';

import { addManager, deleteManager } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';

function AddManagerModal({
  surveyId, managers, onCancel, onChanged,
}) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onClickCancel = () => {
    setEmail('');
    onCancel();
  };

  const onClickDelete = async (manager) => {
    setError(false);
    await deleteManager(surveyId, manager);
    onChanged(false);
  };

  const onClickAdd = async () => {
    setError(false);
    if (email === '' || !validator.isEmail(email)) {
      setError(__('G121'));
      return;
    }

    setIsSaving(true);
    const response = await addManager(surveyId, email);
    setIsSaving(false);
    if (response.error) {
      setError(response.error.message);
      return;
    }

    onChanged();
    setEmail('');
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>{__('G133')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="pb-4">
          <div className="fw-bold">{__('G134')}</div>
          {managers.map(
            (manager, index) => (
              <div className="px-2" key={`add-manager-modal-${index}`}>
                {manager}
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => onClickDelete(manager)}
                  className="px-2 cursor-pointer"
                />
              </div>
            ),
          )}
          {managers.length === 0 && <div className="px-2 fst-italic">{__('G135')}</div>}
        </div>
        <Form.Group className="mb-2">
          <Form.Control
            type="email"
            disabled={isSaving}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onClickAdd();
              }
            }}
            placeholder={__('G117')}
            autoFocus
          />
        </Form.Group>

        {error && (<Alert variant="danger">{error}</Alert>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClickCancel} disabled={isSaving}>
          {__('G106')}
        </Button>
        <Button variant="primary" disabled={isSaving} onClick={onClickAdd}>
          {__('G107')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddManagerModal.propTypes = {
  surveyId: PropTypes.string,
  managers: PropTypes.array,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
};

export default AddManagerModal;
