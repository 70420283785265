import PropTypes from 'prop-types';
import React from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { __ } from '../../../helpers/translations';

function CardService({
  title, description, children, id, image,
}) {
  return (
    <Card className="card-span h-100">
      <div className="card-span-img">
        <img src={image} alt={title} className="h-75 w-75" />
      </div>
      <Card.Body className="pt-6 pb-4">
        <h5 className="mb-2">{title}</h5>
        {description && <p>{description}</p>}
        {children}
        <Row>
          <Col>
            <Link to={`/template/${id}/0`} target="_blank">
              <Button className="mt-3" variant="outline-secondary">{__('G38')}</Button>
            </Link>
          </Col>
          <Col>
            <Link to={`/report/${id}/template`} target="_blank">
              <Button className="mt-3" variant="outline-secondary">{__('G39')}</Button>
            </Link>
          </Col>
        </Row>

      </Card.Body>
    </Card>
  );
}

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  id: PropTypes.string,
  image: PropTypes.string,
};

export default CardService;
