import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';

import hero2 from '../../../assets/itpmetrics/hero2_sm.jpg';
import { __ } from '../../../helpers/translations';
import { useAuthContext } from '../../../hooks/useAuthContext';
import { useLogout } from '../../../hooks/useLogout';
import ContentSection from '../common/ContentSection';
import LoginModal from './LoginModal';

function Hero({ login, logout }) {
  const { logout: doLogOut } = useLogout();
  const [showLogIn, setShowLogIn] = useState(login);
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const headingParts = __('G20', undefined, ['%s']).split('%s');

  useEffect(() => {
    if (logout) {
      doLogOut();
    }
  }, [logout]);

  return (
    <ContentSection image={hero2}>
      <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
        <Col
          md={12}
          lg={9}
          xl={5}
          className="pb-7 pb-xl-9 text-center text-xl-start"
        >
          <h2 className="text-white fw-light">
            {headingParts[0]}
            {' '}
            <span className="fw-bold">
              <Typewriter
                words={[
                  __('G14'),
                  __('G15'),
                  __('G16'),
                  __('G17'),
                  __('G18'),
                  __('G19'),
                ]}
                loop
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </span>
            <br />
            {headingParts[1]}
          </h2>
          <p className="lead text-white opacity-75">
            {__('G4')}
          </p>
          <Button
            variant="outline-light"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-0 py-2"
            onClick={() => {
              if (user) {
                navigate('/dashboard');
              } else {
                setShowLogIn(true);
              }
            }}
          >
            {user ? __('G3') : __('G2')}
            <FontAwesomeIcon icon="play" transform="shrink-6 down-1 right-5" />
          </Button>
        </Col>
        <Col
          xl={{ span: 6, offset: 1 }}
          className="align-self-end mt-4 mt-xl-0"
        />
      </Row>
      <LoginModal show={showLogIn} onHide={() => setShowLogIn(false)} />
    </ContentSection>
  );
}

Hero.propTypes = {
  login: PropTypes.bool,
  logout: PropTypes.bool,
};

Hero.defaultProps = {
  login: false,
  logout: false,
};

export default Hero;
