import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { startSurvey } from '../../../../bridge';
import { requiresReleaseMode } from '../../../../helpers/itpmetricsUtils';
import { __ } from '../../../../helpers/translations';
import SelectReleaseMode from './SelectReleaseMode';

function StartSurveyButton({
  survey = {}, onChanged, demo, disabled,
}) {
  const [showModal, setShowModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [releaseMode, setReleaseMode] = useState(survey.releaseMode);

  const onClickStart = async () => {
    setInProgress(true);
    await startSurvey(survey.surveyId, releaseMode);
    setInProgress(false);
    setShowModal(false);
    onChanged();
  };

  return (
    <>
      <Button
        title={__('G104')}
        size={demo ? 'sm' : 'md'}
        variant="light"
        onClick={() => setShowModal(true)}
        disabled={disabled || demo || inProgress}
      >
        <FontAwesomeIcon icon={faPlay} />
      </Button>
      <Modal show={showModal} centered onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>{__('G104')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{__('G105')}</p>
          {requiresReleaseMode(survey)
          && <SelectReleaseMode initialReleaseMode={releaseMode || survey.releaseMode} onChange={setReleaseMode} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setShowModal(false)}>
            {__('G106')}
          </Button>
          <Button
            variant="primary"
            disabled={inProgress || (requiresReleaseMode(survey) && !releaseMode)}
            onClick={onClickStart}
          >
            {__('G107')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

StartSurveyButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    templateId: PropTypes.string,
    releaseMode: PropTypes.string,
  }),
  onChanged: PropTypes.func,
  demo: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default StartSurveyButton;
