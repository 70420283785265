/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ButtonGroup,
  Row,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { generateRandomResponses, getSurvey, getSurveyStatus } from '../../../../bridge';
import { formatScheduledTime } from '../../../../helpers/itpmetricsUtils';
import { __ } from '../../../../helpers/translations';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import ContentSection from '../../common/ContentSection';
import InfoPopover from '../../common/InfoPopover';
import NotFound from '../../common/NotFound';
import Page from '../../common/Page';
import SignedOut from '../../common/SignedOutDialog';
import AddManagerButton from '../components/AddManagerButton';
import AddParticipantButton from '../components/AddParticipantButton';
import DeleteSurveyButton from '../components/DeleteSurveyButton';
import DownloadRawData from '../components/DownloadRawData';
import EditSurveyNameButton from '../components/EditSurveyNameButton';
import EndSurveyButton from '../components/EndSurveyButton';
import PreviewSurveyButton from '../components/PreviewSurveyButton';
import SendNotificationsButton from '../components/SendNotificationsButton';
import StartSurveyButton from '../components/StartSurveyButton';
import SurveyDashboardCards from '../components/SurveyDashboardCards';
import UpdateScheduledEndTimeButton from '../components/UpdateScheduledEndTimeButton';
import UpdateScheduledTimeButton from '../components/UpdateScheduledTimeButton';
import UploadParticipantsButton from '../components/UploadParticipantsButton';

export default function SurveyDashboard() {
  const { user } = useAuthContext();
  const { surveyId } = useParams();
  const [error, setError] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [respondents, setRespondents] = useState([]);
  const [template, setTemplate] = useState(null);
  const [teams, setTeams] = useState(null);
  const [refreshTimer, setRefreshTimer] = useState(null);
  const [statusRefreshTimer, setStatusRefreshTimer] = useState(null);
  const [isDemoSurvey, setIsDemoSurvey] = useState(false);
  const [demoNeedsResponses, setDemoNeedsResponses] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const onChanged = async (id = surveyId) => {
    const { response, error: e } = await getSurvey(id);
    if (e) {
      setError(e);
      return;
    }
    setSurvey(response.survey);
    setRespondents(response.respondents || []);
    setTemplate(response.template);
  };

  const onStatusChanged = async (id = surveyId) => {
    const { response, error: e } = await getSurveyStatus(id);
    if (e) {
      setError(e);
      return;
    }
    setSurvey(response.survey);
  };

  const onClickGenerateResponses = async () => {
    setIsGenerating(true);
    await generateRandomResponses(surveyId);
    onChanged();
    setIsGenerating(false);
  };

  useEffect(() => {
    if (error || survey) {
      return;
    }

    onChanged();
  }, [survey, surveyId, error]);

  useEffect(() => {
    if (respondents.length) {
      const groupedByTeam = {};
      respondents.forEach((item) => {
        if (!item.team) {
          return;
        }
        groupedByTeam[item.team] = groupedByTeam[item.team] || [];
        groupedByTeam[item.team].push(item);
      });
      setTeams(groupedByTeam);

      if (respondents.filter(({ email }) => email.endsWith('@donotreply.com')).length
        === respondents.length
      ) {
        setDemoNeedsResponses(
          respondents.filter(({ status }) => status !== 'completed').length === respondents.length,
        );
        setIsDemoSurvey(true);
      }
    }
  }, [respondents]);

  useEffect(() => {
    if (!refreshTimer) {
      setRefreshTimer(setInterval(onChanged, 60 * 1000));
    }
    if (!statusRefreshTimer) {
      setStatusRefreshTimer(setInterval(onStatusChanged, 5 * 1000));
    }
  }, [survey]);

  if (!user) {
    return <SignedOut />;
  }

  if (error) {
    return <NotFound subject="survey" />;
  }

  return (
    <Page>
      <ContentSection>
        <div>
          <span className="survey-dashboard-name">{survey?.name}</span>
          {survey && (<InfoPopover item={survey} alignment="left" />)}
        </div>

        {survey && (
        <div>
          <div className="pb-3 pt-3 border-secondary">
            <Row md="auto" className="px-0">
              <ButtonGroup>
                <PreviewSurveyButton survey={survey} />
                <EditSurveyNameButton survey={survey} onChanged={onChanged} />
                <AddParticipantButton survey={survey} template={template} onChanged={onChanged} />
                <UploadParticipantsButton
                  survey={survey}
                  template={template}
                  onChanged={onChanged}
                />
                <SendNotificationsButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={
                    survey.status !== 'in-progress'
                    || !respondents.find((r) => r.status !== 'completed')
                  }
                />
                <AddManagerButton
                  survey={survey}
                  onChanged={onChanged}
                />
                <DownloadRawData survey={survey} />
                <DeleteSurveyButton
                  survey={survey}
                  onDeleted={() => { window.location = '/dashboard'; }}
                />
                <StartSurveyButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status === 'in-progress' || respondents.length === 0}
                />
                <UpdateScheduledTimeButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status === 'in-progress' || respondents.length === 0}
                />
                <EndSurveyButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status !== 'in-progress'}
                />
                <UpdateScheduledEndTimeButton
                  survey={survey}
                  onChanged={onChanged}
                  disabled={survey.status !== 'in-progress' || respondents.length === 0}
                />
              </ButtonGroup>
            </Row>
          </div>

          {survey.status === 'in-progress' && (
          <Alert variant="success" className="px-2 py-1">
            <strong className="px-1">{__('G91')}</strong>
            <span>{__('G92')}</span>
          </Alert>
          )}

          {survey.status === 'ended' && (
          <Alert variant="danger" className="px-2 py-1">
            <strong className="px-1">{__('G93')}</strong>
            <span>{__('G94')}</span>
          </Alert>
          )}

          {survey.scheduledStartAt && survey.status !== 'in-progress' && (
          <Alert variant="info" className="px-2 py-1">
            <span>{__('G96', null, [formatScheduledTime(survey.scheduledStartAt)])}</span>
          </Alert>
          )}

          {survey.scheduledEndAt && survey.status !== 'ended' && (
          <Alert variant="info" className="px-2 py-1">
            <span>{__('G273', null, [formatScheduledTime(survey.scheduledEndAt)])}</span>
          </Alert>
          )}

          {survey.status === 'not-started' && respondents.length > 0 && !survey.scheduledStartAt && (
            <Alert variant="warning" className="px-2 py-1">
              <strong className="px-1">{__('G95')}</strong>
              <br />
              <span className="px-1">{__('G97')}</span>
              <StartSurveyButton demo />
              <br />
              <span className="px-1">{__('G98')}</span>
              <UpdateScheduledTimeButton demo />
            </Alert>
          )}

          {respondents.length > 0 && isDemoSurvey && demoNeedsResponses && survey.status !== 'not-started' && !isGenerating && (
            <Alert variant="warning" className="px-2 py-1">
              <span className="px-1">{__('G99')}</span>
              <a style={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={onClickGenerateResponses}>{__('G100')}</a>
            </Alert>
          )}

          {respondents.length === 0 && (
          <Alert variant="warning" className="px-2 py-1">
            <strong className="px-1">{__('G101')}</strong>
            <br />
            <span className="px-1">{__('G102')}</span>
            <AddParticipantButton demo />
            <br />
            <span className="px-1">{__('G103')}</span>
            <UploadParticipantsButton demo />
          </Alert>
          )}

          {respondents.length > 0 && (
            <SurveyDashboardCards
              respondents={respondents}
              template={template}
              surveyId={surveyId}
              onChanged={onChanged}
              teams={teams}
              releaseMode={survey.releaseMode}
              surveyStatus={survey.status}
            />
          )}

        </div>
        )}
      </ContentSection>
    </Page>
  );
}
