import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { __ } from '../../../helpers/translations';
import { ReportTeamDynamicsBarChart } from './ReportTeamDynamicsBarChart';

export function ReportTeamDynamicsChartSection({ respondent }) {
  const { report } = respondent;
  const plotReport = (bucket, bucketScore, dimensions) => (
    <ReportTeamDynamicsBarChart
      labels={[
        ...dimensions.map(({ name }) => name),
        bucket,
      ]}
      data={[
        ...dimensions.map(({ score }) => score),
        bucketScore,
      ]}
    />
  );

  return (['Communicate', 'Adapt', 'Relate', 'Educate', 'Qualitative'].map(
    // eslint-disable-next-line consistent-return, array-callback-return
    (key) => {
      if (key === 'Qualitative' && report[key].length > 0) {
        return (
          <Card key={`report-section-feedback-${key}`} className="pagebreak py-3 my-3">
            <Card.Body>
              <div className="m-3 p-3">
                <h2>{__('G154')}</h2>
              </div>
              <div className="m-3 p-3">
                {(Array.from(report[key])).map((feedback, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={`report-section-feedback-${index}`}>
                    <i>{feedback}</i>
                    <hr />
                  </div>
                ))}
              </div>
            </Card.Body>
          </Card>
        );
      }
      if (key !== 'Qualitative') {
        return (
          <Card key={`report-section-chart-${key}`} className="pagebreak py-3 my-3">
            <Card.Body>
              <div className="m-3 p-3">
                <h2>{report[key].caption}</h2>
              </div>
              <div className="mx-5">{plotReport(report[key].caption, report[key].score, report[key].dimensions)}</div>
              {report[key].dimensions.map(({ name, content }) => (
                <div key={`report-section-chart-content-${key}-${name}`} className="m-3 p-3">
                  <h5>{name}</h5>
                  <p>{ReactHtmlParser(content)}</p>
                </div>
              ))}
            </Card.Body>
          </Card>
        );
      }
    },
  ));
}

ReportTeamDynamicsChartSection.propTypes = {
  respondent: PropTypes.shape({
    report: PropTypes.object,
  }),
};
