import {
  faCommentMedical,
  faFileCirclePlus,
  faHome,
  faRightFromBracket,
  faScrewdriverWrench,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Nav, NavDropdown, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { __ } from '../../../helpers/translations';
import { useAuthContext } from '../../../hooks/useAuthContext';
import LoginModal from '../../pages/landing/LoginModal';

function LandingRightSideNavItem() {
  const [showLogIn, setShowLogIn] = useState(false);
  const { user } = useAuthContext();

  return (
    <Nav className="ms-auto">
      {!user ? (
        <Nav.Item>
          <Nav.Link as={Link} onClick={() => setShowLogIn(true)}>
            {__('G1')}
          </Nav.Link>
          <LoginModal show={showLogIn} onHide={() => setShowLogIn(false)} />
        </Nav.Item>
      ) : (
        <>
          <Nav.Item className="mt-2 d-none d-lg-block">
            <Nav.Link
              as={Link}
              to="/dashboard"
            >
              <FontAwesomeIcon icon={faHome} size="2x" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="mt-2 d-none d-lg-block">
            <Nav.Link
              as={Link}
              to="/survey/create"
            >
              <FontAwesomeIcon icon={faFileCirclePlus} size="2x" />
            </Nav.Link>
          </Nav.Item>
          {user.features.includes('createFeedbackReview') && (
          <Nav.Item className="mt-2 d-none d-lg-block">
            <Nav.Link
              as={Link}
              to="/review/create"
            >
              <FontAwesomeIcon icon={faCommentMedical} size="2x" />
            </Nav.Link>
          </Nav.Item>
          )}
          <NavDropdown
            title={(
              <>
                <Row>
                  <b>
                    {`${user.firstName || ''} ${user.lastName || ''}`}
                  </b>
                </Row>
                <span className="me-2">{user.email}</span>
              </>
            )}
            id="navbarScrollingDropdown"
            className="d-none d-lg-block"
          >
            <div className="py-2 bg-light dark__bg-1000 rounded-3">
              <NavDropdown.Item href="/profile">
                <FontAwesomeIcon icon={faUser} className="nav-item-icon" />
                <span>{__('G53')}</span>
              </NavDropdown.Item>
              {user.role === 'administrator' && (
                <NavDropdown.Item href="/admin">
                  <FontAwesomeIcon icon={faScrewdriverWrench} className="nav-item-icon" />
                  <span>Admin Panel</span>
                </NavDropdown.Item>
              )}
              <NavDropdown.Item href="/logout">
                <FontAwesomeIcon icon={faRightFromBracket} className="nav-item-icon" />
                <span>{__('G54')}</span>
              </NavDropdown.Item>
            </div>
          </NavDropdown>

          <Nav.Item className="d-lg-none d-xl-none">
            <Nav.Link as={Link} to="/dashboard">
              <FontAwesomeIcon icon={faHome} className="nav-item-icon" />
              <span><span>{__('G55')}</span></span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-lg-none d-xl-none">
            <Nav.Link as={Link} to="/survey/create">
              <FontAwesomeIcon icon={faFileCirclePlus} className="nav-item-icon" />
              <span>{__('G56')}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-lg-none d-xl-none">
            <Nav.Link as={Link} to="/review/create">
              <FontAwesomeIcon icon={faCommentMedical} className="nav-item-icon" />
              <span>{__('G57')}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item className="d-lg-none d-xl-none">
            <Nav.Link as={Link} to="/profile">
              <FontAwesomeIcon icon={faUser} className="nav-item-icon" />
              <span>{__('G53')}</span>
            </Nav.Link>
          </Nav.Item>
          {user.role === 'administrator' && (
            <Nav.Item className="d-lg-none d-xl-none">
              <Nav.Link as={Link} to="/admin">
                <FontAwesomeIcon icon={faScrewdriverWrench} className="nav-item-icon" />
                <span>Admin Panel</span>
              </Nav.Link>
            </Nav.Item>
          )}
          <Nav.Item className="d-lg-none d-xl-none">
            <Nav.Link as={Link} to="/logout">
              <FontAwesomeIcon icon={faRightFromBracket} className="nav-item-icon" />
              <span>{__('G54')}</span>
            </Nav.Link>
          </Nav.Item>
        </>
      )}
    </Nav>
  );
}

export default LandingRightSideNavItem;
