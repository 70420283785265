import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Badge, Card, Col, Row,
} from 'react-bootstrap';

import { __ } from '../../../../helpers/translations';
import ScorePill from '../../common/ScorePill';
import TeamParticipantsModal from '../modals/TeamParticipantsModal';

function TeamCard({
  respondents, surveyId, surveyStatus, onChanged, teamName, releaseMode,
}) {
  const [showTeamParticipantsModal, setShowTeamParticipantsModal] = useState(false);
  const [teamCompleted, setTeamCompleted] = useState(false);
  const [notApproved, setNotApproved] = useState(false);

  const hasConfidentialFeedback = respondents
    .filter(({ response }) => !!response.Confidential).length > 0;

  useEffect(() => {
    setNotApproved(!!respondents.find((r) => r.status === 'completed' && releaseMode === 'review' && !r.reviewed));
    setTeamCompleted(!respondents.find((r) => r.status !== 'completed'));
  }, [respondents]);

  return (
    <>
      <Card
        onClick={() => setShowTeamParticipantsModal(true)}
        role="button"
        tabIndex={0}
        className="m-2 p-2 h-100"
        data-testid="team-card"
      >
        <Card.Body className="p-2">
          <Row>
            <Col xs={9} xl={10} className="d-flex flex-column">
              <div className="flex-grow-1">
                <Card.Title className="mb-2 text-dark">{`Team ${teamName}`}</Card.Title>
                {respondents.map((respondent) => (
                  <div key={respondent.respondentId}>
                    <Card.Text className="text-muted small">
                      {respondent.status === 'not-started' && <Badge pill bg="light" className="p-1">{' '}</Badge>}
                      {respondent.status === 'completed' && <Badge pill bg="success" className="p-1">{' '}</Badge>}
                      {respondent.status === 'in-progress' && <Badge pill bg="secondary" className="p-1">{' '}</Badge>}
                      {respondent.peerScore && <ScorePill score={respondent.peerScore} />}
                      {` ${respondent.firstName} ${respondent.lastName}`}
                    </Card.Text>
                  </div>
                ))}
                {respondents.length < 3 && (
                  <div className="text-danger small pt-2">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    <span className="px-1">{__('G144')}</span>
                  </div>
                )}
                {surveyStatus === 'ended' && respondents.filter(({ status }) => status === 'completed').length < 3 && (
                <div className="text-danger small pt-2">
                  <FontAwesomeIcon icon={faTriangleExclamation} />
                  <span className="px-1">{__('G145')}</span>
                </div>
                )}
                {releaseMode === 'review' && notApproved && (teamCompleted || surveyStatus === 'ended') && (
                  <div className="text-danger small pt-2">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    <span className="px-1">{__('G146')}</span>
                  </div>
                )}
                {hasConfidentialFeedback && (
                  <div className="text-warning small pt-2">
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    <span className="px-1">{__('G147')}</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      {showTeamParticipantsModal && (
      <TeamParticipantsModal
        surveyId={surveyId}
        teamName={teamName}
        respondents={respondents}
        onClose={() => setShowTeamParticipantsModal(false)}
        onChanged={onChanged}
        releaseMode={releaseMode}
        teamCompleted={teamCompleted}
        notApproved={notApproved}
        surveyStatus={surveyStatus}
      />
      )}
    </>
  );
}

TeamCard.propTypes = {
  respondents: PropTypes.array,
  teamName: PropTypes.string,
  surveyId: PropTypes.string,
  onChanged: PropTypes.func,
  releaseMode: PropTypes.string,
  surveyStatus: PropTypes.string,
};

export default TeamCard;
