import React from 'react';

import { __ } from '../../../helpers/translations';
import ErrorDialog from './ErrorDialog';

function SignedOut() {
  return (
    <ErrorDialog
      content={(
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {__('G274')}
        </p>
      )}
    />
  );
}

export default SignedOut;
