import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import {
  Alert,
  ButtonGroup,
  OverlayTrigger,
  Popover,
  Row,
  Table,
} from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getReview } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import ContentSection from '../../common/ContentSection';
import Loading from '../../common/Loading';
import NotFound from '../../common/NotFound';
import Page from '../../common/Page';
import SignedOut from '../../common/SignedOutDialog';
import AddReviewParticipantButton from '../components/AddReviewParticipantButton';
import DeleteReviewButton from '../components/DeleteReviewButton';
import EditReviewNameButton from '../components/EditReviewNameButton';
import ReviewDashboardCards from '../components/ReviewDashboardCards';
import SendReviewNotificationsButton from '../components/SendReviewNotificationsButton';
import UploadReviewParticipantsButton from '../components/UploadReviewParticipantsButton';

export default function ReviewDashboard() {
  const { user } = useAuthContext();
  const { reviewId } = useParams();
  const [error, setError] = useState(null);
  const [review, setReview] = useState(null);

  const onChanged = async () => {
    const { response, error: e } = await getReview(reviewId);
    if (e) {
      setError(e);
      return;
    }
    setReview(response.review);
  };

  useEffect(() => {
    if (error || review || !reviewId) {
      return;
    }

    onChanged(reviewId);
  }, [review, reviewId, error]);

  if (!user) {
    return <SignedOut />;
  }

  if (error) {
    return <NotFound subject="review" />;
  }

  if (!review) {
    return <Loading />;
  }

  return (
    <Page>
      <ContentSection>
        <div>
          <span className="review-dashboard-name">{review?.name}</span>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Popover>
                <Popover.Body className="tooltip-popover">
                  <Table borderless size="sm" className="tooltip-table">
                    <tbody>
                      <tr>
                        <td>{__('G183')}</td>
                        <td>
                          {review.participants.length}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Popover.Body>
              </Popover>
              )}
          >
            <FontAwesomeIcon icon={faCircleInfo} style={{ marginLeft: '10px', marginTop: '5px' }} />
          </OverlayTrigger>
        </div>

        {review && (
        <div>
          <div className="pb-3 pt-3 border-secondary">
            <Row md="auto" className="px-2">
              <ButtonGroup>
                <EditReviewNameButton review={review} onChanged={onChanged} />
                <AddReviewParticipantButton reviewId={reviewId} onChanged={onChanged} />
                <SendReviewNotificationsButton
                  review={review}
                  onChanged={onChanged}
                />
                <UploadReviewParticipantsButton
                  reviewId={reviewId}
                  onChanged={onChanged}
                />
                <DeleteReviewButton
                  reviewId={reviewId}
                  onDeleted={() => { window.location = '/dashboard'; }}
                />
              </ButtonGroup>
            </Row>

          </div>

          {review.participants.length === 0 && (
          <Alert variant="warning" className="px-2 py-1">
            {__('G177')}
            <br />
            {__('G102')}
            <AddReviewParticipantButton reviewId={reviewId} onChanged={onChanged} demo />
            <br />
            {__('G103')}
            <UploadReviewParticipantsButton reviewId={reviewId} onChanged={onChanged} demo />
          </Alert>
          )}

          {review.participants.length > 0 && (
            <ReviewDashboardCards
              participants={review.participants}
              reviewId={reviewId}
              onChanged={onChanged}
            />
          )}

        </div>
        )}
      </ContentSection>
    </Page>
  );
}
