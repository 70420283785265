import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { conflictSections } from './ReportConflictConcernChart';

export function ReportConflictChart({ report, activeKey, title }) {
  const tileOrderedEntries = Object.keys(conflictSections)
    .map((childKey) => ({
      ...conflictSections[childKey],
      childKey,
      childValue: report[childKey],
    }));

  const getBackground = (key) => {
    if (!activeKey || key === activeKey) {
      return conflictSections[key].color;
    }
    return 'white';
  };

  return (
    <div className="m-3 p-3">
      { title && <h4 className="report-conflict-chart-center-header">{title}</h4>}
      <Row>
        <Col className="report-conflict-chart-container-col">
          <div className="report-conflict-chart-container justify-content-center">
            {tileOrderedEntries.map(
              ({
                childKey, childValue, labelPlacement, caption,
              }) => (
                <div
                  key={`report-conflictmanagement-${childKey}-${activeKey}`}
                  className={classNames([
                    'report-conflict-chart-shape',
                    { 'report-center-shape': childKey === 'Compromising' },
                    { 'report-conflict-chart-shape-primary': childKey === activeKey },
                  ])}
                  style={{ backgroundColor: getBackground(childKey) }}
                >
                  {labelPlacement === 'top'
                  && (
                    <>
                      <h4 className="report-center-text mt-2">
                        {caption}
                      </h4>
                      {(!activeKey || String(childKey) === String(activeKey)) && (
                      <h5 className="report-center-text report-center-text-percentage">
                        {(childValue.percentile || 0).toFixed(0)}
                      </h5>
                      )}
                    </>
                  )}

                  {labelPlacement === 'bottom'
                  && (!activeKey || String(childKey) === String(activeKey)) && (
                  <>
                    <h5 className="report-center-text report-center-text-percentage mt-5">
                      {(childValue.percentile || 0).toFixed(0)}
                    </h5>
                    <h4 className="report-center-text report-center-text-bottom">
                      {caption}
                    </h4>
                  </>
                  )}
                </div>
              ),
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}

ReportConflictChart.propTypes = {
  activeKey: PropTypes.string,
  title: PropTypes.string,
  report: PropTypes.shape({
    Dominating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Avoiding: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Integrating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Accommodating: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
    Compromising: PropTypes.shape({
      percentile: PropTypes.number,
      message: PropTypes.string,
      score: PropTypes.string,
      IdealUsesTitle: PropTypes.string,
      IdealUsesContent: PropTypes.string,
      ExamplesTitle: PropTypes.string,
      ExamplesContent: PropTypes.string,
    }),
  }),
};
