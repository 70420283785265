/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';

import { ReportPersonalityBarChart } from './ReportPersonalityBarChart';

export function ReportPersonalityChartSection({ respondent }) {
  const getScore = (trait) => {
    const score = {};
    Object.entries(trait).forEach(([, value]) => {
      score[value.caption] = value.percentile;
    });
    return score;
  };

  const plotReport = (scores) => (
    <ReportPersonalityBarChart label={Object.keys(scores)} data={Object.values(scores)} />
  );

  return (Object.entries(respondent.report).map(
    ([bfiKey, bfiValue]) => (
      <Card key={`report-section-chart-${bfiKey}`} className="pagebreak py-3 my-3">
        <Card.Body>
          <div className="m-3 p-3">
            <h2>{bfiValue[bfiKey].caption}</h2>
          </div>
          <div className="mx-5">{plotReport(getScore(bfiValue))}</div>
          {Object.entries(bfiValue).map(([key, value]) => (
            <div key={key} className="m-3 p-3">
              <h5>{key === bfiKey ? null : value.caption}</h5>
              <p>{value.description}</p>
            </div>
          ))}
        </Card.Body>
      </Card>
    ),
  ));
}

ReportPersonalityChartSection.propTypes = {
  respondent: PropTypes.shape({
    report: PropTypes.shape({
      Neuroticism: PropTypes.shape({
        Anixiety: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Anger: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Depression: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        SelfConsciousness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Immoderation: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Vulnerability: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Neuroticism: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
      }),
      Extraversion: PropTypes.shape({
        Friendliness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Gregariousness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Assertiveness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        ActivityLevel: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        ExcitementSeeking: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Cheerfulness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Extraversion: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
      }),
      Openness: PropTypes.shape({
        Imagination: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        ArtisticInterests: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Emotionality: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Adventureousness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Intellect: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Liberalism: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Openness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
      }),
      Agreeableness: PropTypes.shape({
        Morality: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Altruism: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Cooperation: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Modesty: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Sympathy: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Agreeableness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
      }),
      Conscientiousness: PropTypes.shape({
        SelfEfficicacy: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Orderliness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Dutifulness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        AchievementStriving: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        SelfDiscipline: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Cautiousness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
        Conscientiousness: PropTypes.shape({
          percentile: PropTypes.number,
          description: PropTypes.string,
          caption: PropTypes.string,
        }),
      }),
      status: PropTypes.string,
      surveyId: PropTypes.string,
      templateId: PropTypes.string,
      updatedAt: PropTypes.string,
      respondentId: PropTypes.string,
    }),
  }),
};

ReportPersonalityChartSection.defaultProps = {
  respondent: { report: null },
};
