import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert,
  Button, Form, Modal,
} from 'react-bootstrap';
import validator from 'validator';

import { addRespondents } from '../../../../bridge';
import { isIndividualTemplateType } from '../../../../helpers/itpmetricsUtils';
import { __ } from '../../../../helpers/translations';

function AddParticipantModal({
  surveyId, templateType, onCancel, onChanged, disableTeam, teamName,
}) {
  const [participantInput, setParticipantInput] = useState({
    firstName: '', lastName: '', email: '', team: teamName || '',
  });
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const onChangeInput = (e, inputType) => {
    setParticipantInput({
      ...participantInput,
      [inputType]: e.target.value,
    });
  };

  const onClickCancel = () => {
    setParticipantInput({ firstName: '', lastName: '', email: '' });
    onCancel();
  };

  const onClickAdd = async () => {
    setError(false);
    const entry = {
      firstName: participantInput.firstName.trim(),
      lastName: participantInput.lastName.trim(),
      email: participantInput.email.trim().toLowerCase(),
      team: participantInput.team.trim(),
    };

    if (!entry.firstName || !entry.lastName) {
      setError(__('G120'));
      return;
    }

    if (!validator.isEmail(entry.email)) {
      setError(__('G121'));
      return;
    }

    if (!isIndividualTemplateType(templateType) && entry.team.length === 0) {
      setError(__('G122', null, [`${entry.firstName} ${entry.lastName}`]));
      return;
    }

    setIsSaving(true);
    const response = await addRespondents(surveyId, [entry]);
    setIsSaving(false);
    if (response.error) {
      setError(response.error.message);
      return;
    }

    onChanged();
    setParticipantInput({
      firstName: '', lastName: '', email: '', team: '',
    });
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>{__('G115')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-2">
          <Form.Label className="mb-0">
            {__('G59')}
          </Form.Label>
          <Form.Control
            type="text"
            autoFocus
            disabled={isSaving}
            value={participantInput.firstName}
            onChange={(e) => onChangeInput(e, 'firstName')}
            placeholder={__('G60')}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="mb-0">
            {__('G61')}
          </Form.Label>
          <Form.Control
            type="text"
            disabled={isSaving}
            value={participantInput.lastName}
            onChange={(e) => onChangeInput(e, 'lastName')}
            placeholder={__('G62')}
          />
        </Form.Group>
        <Form.Group className="mb-2">
          <Form.Label className="mb-0">
            {__('G116')}
          </Form.Label>
          <Form.Control
            type="email"
            disabled={isSaving}
            value={participantInput.email}
            onChange={(e) => onChangeInput(e, 'email')}
            placeholder={__('G117')}
          />
        </Form.Group>
        {!isIndividualTemplateType(templateType) && (
        <Form.Group className="mb-2">
          <Form.Label className="mb-0">
            {__('G118')}
          </Form.Label>
          <Form.Control
            type="text"
            disabled={isSaving || disableTeam}
            value={participantInput.team}
            onChange={(e) => onChangeInput(e, 'team')}
            placeholder={__('G119')}
          />
        </Form.Group>
        )}

        {error && (<Alert variant="danger">{error}</Alert>)}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onClickCancel} disabled={isSaving}>
          {__('G106')}
        </Button>
        <Button variant="primary" disabled={isSaving} onClick={onClickAdd}>
          {__('G107')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

AddParticipantModal.propTypes = {
  surveyId: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
  templateType: PropTypes.string,
  showTeamParticipantsModal: PropTypes.bool,
  teamName: PropTypes.string,
  disableTeam: PropTypes.bool,
};

export default AddParticipantModal;
