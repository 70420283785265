import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { logIn } from '../../../bridge';
import AppContext from '../../../context/Context';
import { logEvent } from '../../../helpers/logger';
import { __ } from '../../../helpers/translations';

function LoginModal({ show, onHide }) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { config: { rememberedEmail = '' }, setConfig } = useContext(AppContext);
  const [email, setEmail] = useState(params.get('email') ?? rememberedEmail);

  const defaultMessage = __('G42');
  const [message, setMessage] = useState(defaultMessage);
  const [hideForm, setHideForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleFieldChange = (e) => {
    setEmail(e.target.value);
  };

  useEffect(() => {
    if (!show) {
      setMessage(defaultMessage);
      setHideForm(false);
    }
  }, [show]);

  const onComplete = () => {
    setMessage(__('G44'));
    setHideForm(true);
  };

  const handleSubmit = async (token) => {
    const lowercased = email.toLowerCase();
    setConfig('rememberedEmail', lowercased);
    setIsLoading(true);
    await logIn(lowercased, null, token);
    onComplete(lowercased);
    setIsLoading(false);
    logEvent('sign_in', { user: { email: lowercased } });
  };

  const preHandleSubmit = () => {
    // eslint-disable-next-line no-undef
    grecaptcha.ready(async () => {
      // eslint-disable-next-line no-undef
      const token = await grecaptcha.execute(
        '6LfI_a8qAAAAACy8dHhFesNbmrM5mbdbBhdbwCRD',
        { action: 'submit' },
      );
      handleSubmit(token);
    });
  };

  return (
    <Modal
      show={show}
      centered
      onHide={() => onHide()}
    >
      <Modal.Header>
        <Modal.Title>{__('G1')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{message}</p>
        {!hideForm && (
        <Form.Group>
          <Form.Control
            placeholder=""
            value={email}
            name="email"
            onChange={handleFieldChange}
            type="email"
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                preHandleSubmit();
              }
            }}
            autoFocus
          />
          <span className="text-muted" style={{ fontSize: '65%' }}>
            This site is protected by reCAPTCHA and the Google
            {' '}
            <a href="https://policies.google.com/privacy">Privacy Policy</a>
            {' '}
            and
            {' '}
            <a href="https://policies.google.com/terms">Terms of Service</a>
            {' '}
            apply.
          </span>
        </Form.Group>
        )}
      </Modal.Body>
      {!hideForm && (
        <Modal.Footer>
          <Button
            color="primary"
            disabled={!email || isLoading}
            onClick={preHandleSubmit}
          >
            {isLoading ? __('G43') : __('G1')}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

LoginModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
};

LoginModal.defaultProps = {
  show: false,
  onHide: () => {},
};

export default LoginModal;
