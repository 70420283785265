import { format } from 'date-fns';
import { de } from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import { es } from 'date-fns/locale/es';
import { fr } from 'date-fns/locale/fr';
import { it } from 'date-fns/locale/it';
import { pt } from 'date-fns/locale/pt';

import { getRespondent } from '../bridge';
import { getCookieValue } from './utils';

const locales = {
  en,
  fr,
  pt,
  es,
  de,
  it,
};

export const isPeerFeedbackTemplate = (template) => [
  'peerFeedback',
  'peerFeedbackTeamDynamics',
].includes(template?.templateId);

export const isEntFeedbackTemplate = (template) => template?.templateId === 'entEffectiveness';

export const isIndividualTemplateType = (type) => type === 'individual';

export const isIndividualTemplate = (template) => isIndividualTemplateType(template?.type);

export const formatTime = (time) => (time ? format(new Date(time), 'yyyy-MM-dd hh:mm aa') : 'N/A');

export const formatDate = (date) => (date ? format(new Date(date), 'PPP', {
  locale: locales[getCookieValue('language')],
}) : '');

export const formatShortDate = (date) => (date ? format(new Date(date), 'yyyy-MM') : '');

export const formatScheduledTime = (date) => format(date, 'PPPp', {
  locale: locales[getCookieValue('language')],
});

export const formatCompletionPercentage = ({ top, bottom }) => {
  const percentage = Math.min(100, (
    (Number(top) || 0) / (Number(bottom) || 1)
  ) * 100);
  return `${percentage.toFixed(0)}%`;
};

export const findProgress = (object, index, max) => {
  const priorPages = object.pages.slice(0, index + 1);
  const current = priorPages.reduce((acc, page) => acc + page.reduce((pageAcc, item) => {
    if (['caption'].includes(item.type)) {
      return pageAcc;
    }
    return pageAcc + 1;
  }, 0), 0);

  return (current / max) * 100;
};

export const requiresReleaseMode = (survey) => [
  'peerFeedback',
  'teamDynamics',
  'peerFeedbackTeamDynamics',
  'teamPlayer',
  'teamPlayerFacets',
  'entEffectiveness',
].includes(survey.templateId);

export const redirectToRespondent = async () => {
  const respondentId = localStorage.getItem('respondentId');
  if (!respondentId) {
    return null;
  }
  const { response } = await getRespondent(respondentId);
  if (!response || !response.respondent) {
    localStorage.removeItem('respondentId');
    return null;
  }
  if (response.survey.deleted || response.respondent.deleted) {
    localStorage.removeItem('respondentId');
    return false;
  }
  const { status } = response.respondent;
  if (status === 'completed') {
    window.location = `/report/${respondentId}`;
  } else {
    window.location = `/respondent/${respondentId}/0`;
  }
  localStorage.removeItem('respondentId');
  return true;
};

export const getCopyrightMessage = () => `© Copyright ${new Date().getFullYear()} itpmetrics.com`;
