import { RadarChart as eRadarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import PropTypes from 'prop-types';
import React from 'react';

import { isEntFeedbackTemplate, isPeerFeedbackTemplate } from '../../../helpers/itpmetricsUtils';
import { __ } from '../../../helpers/translations';
import {
  ITPMETRICS_BLUE, ITPMETRICS_BLUE_LIGHT, ITPMETRICS_YELLOW, ITPMETRICS_YELLOW_LIGHT,
} from '../common/Colours';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  eRadarChart,
  CanvasRenderer,
  LegendComponent,
  MarkLineComponent,
]);

export function ReportSpiderChart({ type, report }) {
  const selfRatingScores = [];
  const peerRatingScores = [];
  Object.keys(report.peerRating || []).forEach((key) => {
    if (key !== 'Qualitative') {
      const peerScore = report.peerRating[key].score;
      peerRatingScores.push(peerScore === 'NaN' ? 'N/A' : peerScore);
      const selfScore = report.selfRating[key].score;
      selfRatingScores.push(selfScore === 'NaN' ? 'N/A' : selfScore);
    }
  });
  selfRatingScores.reverse();
  selfRatingScores.length = selfRatingScores.unshift(selfRatingScores[selfRatingScores.length - 1]) - 1;
  peerRatingScores.reverse();
  peerRatingScores.length = peerRatingScores.unshift(peerRatingScores[peerRatingScores.length - 1]) - 1;

  for (let i = 0; i < selfRatingScores.length; i += 1) {
    if (selfRatingScores[i] === 0) {
      selfRatingScores[i] = 'N/A';
    }
  }
  for (let i = 0; i < peerRatingScores.length; i += 1) {
    if (peerRatingScores[i] === 0) {
      peerRatingScores[i] = 'N/A';
    }
  }

  // eslint-disable-next-line no-nested-ternary
  const indicator = isPeerFeedbackTemplate({ templateId: type })
    ? [
      { name: __('G228'), max: 5 },
      { name: __('G229'), max: 5 },
      { name: __('G230'), max: 5 },
      { name: __('G231'), max: 5 },
      { name: __('G232'), max: 5 },
    ]
    : isEntFeedbackTemplate({ templateId: type })
      ? [
        { name: __('G232'), max: 5 },
        { name: __('G233'), max: 5 },
        { name: __('G234'), max: 5 },
        { name: __('G235'), max: 5 },
        { name: __('G236'), max: 5 },
        { name: __('G237'), max: 5 },
      ]
      : [
        { name: __('G238'), max: 5 },
        { name: __('G239'), max: 5 },
        { name: __('G240'), max: 5 },
        { name: __('G241'), max: 5 },
        { name: __('G242'), max: 5 },
        { name: __('G243'), max: 5 },
      ];
  indicator.reverse();
  indicator.length = indicator.unshift(indicator[indicator.length - 1]) - 1;

  const getOption = () => ({
    legend: {
      orient: 'vertical',
      left: 'left',
      textStyle: {
        color: 'black',
        fontSize: 14,
      },
    },
    tooltip: {
      trigger: 'item',
      padding: [7, 10],
      backgroundColor: 'white',
      borderColor: 'gray-300',
      textStyle: { color: 'gray-1100' },
      borderWidth: 1,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
    },

    radar: {
      indicator,
      radius: 200,
      splitLine: {
        lineStyle: {
          color: 'black',
        },
      },
      axisName: {
        color: 'black',
        fontSize: 14,
      },
    },

    series: [
      {
        type: 'radar',
        data: [
          {
            value: peerRatingScores,
            name: __('G227'),
            itemStyle: {
              color: ITPMETRICS_BLUE,
            },
            lineStyle: {
              width: 4,
            },
            label: {
              show: true,
              formatter(params) {
                return params.value;
              },
            },
            areaStyle: {
              color: ITPMETRICS_BLUE_LIGHT,
            },
          },
          {
            value: selfRatingScores,
            name: __('G226'),
            itemStyle: {
              color: ITPMETRICS_YELLOW,
            },
            label: {
              show: true,
              formatter(params) {
                return params.value;
              },
            },
            areaStyle: {
              color: ITPMETRICS_YELLOW_LIGHT,
            },
          },
        ],
      },
    ],
  });
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOption()}
      style={{ height: '500px', width: '700px' }}
    />
  );
}

ReportSpiderChart.propTypes = {
  report: PropTypes.shape({
    peerRating: PropTypes.object,
    selfRating: PropTypes.object,
  }),
  type: PropTypes.string,
};

ReportSpiderChart.defaultProps = {
  report: { peerRating: {}, selfRating: {} },
  type: '',
};
