import React from 'react';

import { __ } from '../../../helpers/translations';

export function ReportConflictProcessChart() {
  const elements = [
    __('G244'),
    __('G245'),
    __('G246'),
    __('G247'),
  ];
  return (
    <div className="report-conflict-chart-process-shapecontainer">
      {elements.map((element) => (
        <div className="report-conflict-chart-process-shape" key={element}>
          <h5 className="report-conflict-process-text mt-5">{element}</h5>
        </div>
      ))}
    </div>
  );
}
