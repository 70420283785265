import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Alert, Button, Form, Modal,
} from 'react-bootstrap';

import { updateReviewName } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';

function ReviewNameModal({
  reviewId, name, onChanged, onCancel,
}) {
  const [text, setText] = useState(name);
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  const onClickSave = async () => {
    if (text.trim() === '') {
      setIsNameEmpty(true);
      return;
    }
    await updateReviewName(reviewId, text.trim());
    setIsNameEmpty(false);
    onChanged();
  };

  return (
    <Modal show centered onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>{__('G178')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="review-name">
          <Form.Control
            type="text"
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={__('G178')}
            onFocus={(e) => e.target.select()}
          />
        </Form.Group>
        {isNameEmpty && (
          <Alert variant="danger">{__('G179')}</Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onCancel}>
          {__('G106')}
        </Button>
        <Button variant="primary" onClick={onClickSave}>
          {__('G107')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

ReviewNameModal.propTypes = {
  reviewId: PropTypes.string,
  name: PropTypes.string,
  onCancel: PropTypes.func,
  onChanged: PropTypes.func,
};

export default ReviewNameModal;
