import { faPersonChalkboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { __ } from '../../../../helpers/translations';
import AddManagerModal from '../modals/AddManagerModal';

function AddManagerButton({ survey, onChanged }) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Button
        title={__('G263')}
        size="md"
        variant="light"
        onClick={() => setShowModal(true)}
      >
        <FontAwesomeIcon icon={faPersonChalkboard} />
      </Button>
      {showModal && (
      <AddManagerModal
        surveyId={survey.surveyId}
        managers={survey.managers || []}
        onCancel={() => setShowModal(false)}
        onChanged={async (close = true) => {
          if (close) {
            setShowModal(false);
          }
          await onChanged();
        }}
      />
      )}
    </>

  );
}

AddManagerButton.propTypes = {
  survey: PropTypes.shape({
    surveyId: PropTypes.string.isRequired,
    managers: PropTypes.array,
  }),
  onChanged: PropTypes.func,
};

export default AddManagerButton;
