import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Button, Card, Col, Row,
} from 'react-bootstrap';

import { createReview } from '../../../../bridge';
import { __ } from '../../../../helpers/translations';
import { reviews } from '../../landing/Services';

const reviewInfoArray = reviews.flatMap((subArray) => subArray);

function SelectReviewModal({ onReviewSelected }) {
  const [selectedReview, setSelectedReview] = useState(null);

  const onClickReview = (review) => {
    setSelectedReview(review);
  };

  const onSelected = async () => {
    const { response } = await createReview({ type: selectedReview.type });
    onReviewSelected(response);
  };

  return (
    <Card>
      <Row className="justify-content-center p-4">
        <Col lg={5}>
          <Card.Header className="text-center fs-2 fw-bold">
            {__('G175')}
          </Card.Header>
          <Card.Body>
            {reviewInfoArray.map((review) => (
              <div
                key={`review-${review.id}`}
                className="d-flex align-items-center mb-4"
                onClick={() => onClickReview(review)}
                role="button"
                tabIndex={0}
              >
                <div className="mr-3">
                  <img src={review.image} alt={review.title} className="me-3" width="50px" />
                </div>
                <div>
                  <h5>{review.title}</h5>
                </div>
              </div>
            ))}
          </Card.Body>
        </Col>
        <Col lg={7} className="p-4">
          {selectedReview && (
            <>
              <Row>
                <div className="text-center">
                  <img src={selectedReview.image} alt={selectedReview.title} />
                  <h4 className="mt-4 mb-3">{selectedReview.title}</h4>
                  {selectedReview.type
                  && <h6>{selectedReview.type}</h6>}
                </div>
                {selectedReview.description && (
                <p className="mt-4">{selectedReview.description}</p>
                )}
              </Row>
              <Row className="justify-content-center py-4">
                <Col className="px-0">
                  <Button
                    onClick={onSelected}
                    className="btn btn-primary float-end"
                  >
                    {__('G169')}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </Card>
  );
}

SelectReviewModal.propTypes = {
  onReviewSelected: PropTypes.func,
};

export default SelectReviewModal;
