import PropTypes from 'prop-types';
import React from 'react';
import {
  Form,
} from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

import { __ } from '../../../helpers/translations';

export default function PrivacyPolicy({
  formData, setFormData, privacyPolicy, policyId,
}) {
  const handleChange = () => {
    setFormData({
      ...formData,
      policyId: !formData.policyId ? policyId : '',
    });
  };

  return (
    <div>
      <Form.Control
        isInvalid={!formData.policyId}
        as="fieldset"
        onClick={handleChange}
        style={{
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <Form.Check type="checkbox" id="acceptCheckbox" className="form-check offset-0 my-2">
          <Form.Check.Input
            type="checkbox"
            name="policyIdToggle"
            checked={!!formData.policyId}
            onChange={handleChange}
            style={{ cursor: 'pointer' }}
            aria-label={__('G68')}
          />
          {__('G68')}
        </Form.Check>
      </Form.Control>
      {!formData.isAccepted && (
      <Form.Control.Feedback type="invalid">
        {__('G69')}
      </Form.Control.Feedback>
      )}
      <div
        className="paragraph overflow-y-scroll mb-3 my-3"
        style={{
          maxHeight: '225px',
          border: '2px solid #D8E2EF',
          borderRadius: '5px',
        }}
      >
        <div className="mx-3 my-3">
          {ReactHtmlParser(privacyPolicy)}
        </div>
      </div>
    </div>
  );
}

PrivacyPolicy.propTypes = {
  formData: PropTypes.any,
  setFormData: PropTypes.func,
  privacyPolicy: PropTypes.string,
  policyId: PropTypes.string,
};

PrivacyPolicy.defaultProps = {
  formData: {},
  setFormData: () => {},
  privacyPolicy: '',
  policyId: '',
};
